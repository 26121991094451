:root {
  --font-monument: "Monument Regular", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
  --font-monument-mono: "Monument Semi-Mono", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
  --color-secondary: #cecac3;
  --color-primary: black;
  --ease-smooth: cubic-bezier(.45, .02, .09, .98);
  --border-radius: 1rem;
  --border-thickness: 3px;
}

*, body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  text-wrap: balance;
  font-family: var(--font-monument-mono);
  box-sizing: border-box;
  font-weight: 400;
}

@media (width >= 768px) {
  :is(*, body) {
    text-align: center;
  }
}

body {
  color: var(--color-secondary);
  background: var(--color-primary);
  place-items: center;
  margin: 0;
  padding: 0;
  font-size: 16px;
  display: grid;
}

main {
  z-index: 1;
  text-wrap: balance;
  mix-blend-mode: difference;
  letter-spacing: -.01em;
  font-size: 2rem;
  line-height: 1.15;
  font-family: var(--font-monument);
  flex-direction: column;
  gap: 6rem;
  padding: 1rem 1rem 0;
  display: flex;
  position: relative;
}

@media (width >= 768px) {
  main {
    gap: 8rem;
    padding: 20rem 5rem 0;
    font-size: 3.35vw;
    line-height: 1.05;
  }
}

video {
  object-fit: cover;
  pointer-events: none;
  width: 100%;
  height: 100lvh;
  position: fixed;
  top: 0;
  left: 0;
}

footer {
  background: var(--color-secondary);
  color: var(--color-primary);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  padding: 3rem;
  font-size: 1rem;
}

@media (width >= 768px) {
  footer {
    padding: 7rem 3rem;
    font-size: 1.5rem;
  }
}

h1, h2, p {
  font-size: inherit;
  margin: 0;
}

h1, h2 {
  margin-bottom: 3rem;
}

p {
  margin: 0 auto;
}

p a {
  text-decoration: underline;
  text-decoration-thickness: var(--border-thickness);
}

a {
  cursor: pointer;
  color: var(--color-secondary);
  font-family: inherit;
  text-decoration: none;
  display: inline-block;
}

li {
  all: unset;
  font-family: var(--font-monument);
}

button {
  all: unset;
  cursor: pointer;
}

ul {
  all: unset;
  flex-flow: wrap;
  gap: .75rem;
  display: flex;
}

@media (width >= 768px) {
  ul {
    justify-content: center;
    margin: 0 auto;
  }
}

ul a {
  border-radius: var(--border-radius);
  outline: var(--border-thickness) solid var(--color-secondary);
  background: var(--color-secondary);
  color: var(--color-primary);
  padding: .25rem .95rem;
  text-decoration: none;
}

li:not(:has(a)) {
  border-radius: var(--border-radius);
  outline: var(--border-thickness) solid var(--color-secondary);
  background: var(--color-primary);
  color: var(--color-secondary);
  padding: .25rem .95rem;
}

@media (pointer: fine) {
  ul a:hover {
    background: var(--color-primary);
    color: var(--color-secondary);
  }
}

ul a:focus-visible, ul a:active {
  opacity: .8;
}

.researchModal {
  --researchModal-padding-y: 10rem;
  background: var(--color-secondary);
  color: var(--color-primary);
  transition: border-radius .2s var(--ease-smooth);
  border: var(--border-thickness) solid transparent;
  border-radius: 50%;
  width: 100%;
  overflow: hidden;
}

.researchModal:hover, .researchModal:focus-visible, .researchModal--active {
  border-radius: var(--border-radius);
  transition-speed: .1s;
}

.researchModal_toggle-btn {
  padding: var(--researchModal-padding-y) 0;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
}

.researchModal_content {
  height: 0;
}

.researchModal_content--show {
  margin-bottom: var(--researchModal-padding-y);
  height: 100%;
}
/*# sourceMappingURL=index.13b798c7.css.map */
