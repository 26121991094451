:root {
	/* Fonts */
	--font-monument: 'Monument Regular', -apple-system, BlinkMacSystemFont,
		avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell,
		Ubuntu, roboto, noto, arial, sans-serif;
	--font-monument-mono: 'Monument Semi-Mono', -apple-system,
		BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue,
		helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;

	/* Colors */
	--color-secondary: rgb(206, 202, 195);
	--color-primary: black;

	/* Easings */
	--ease-smooth: cubic-bezier(0.45, 0.02, 0.09, 0.98);

	/* Borders */
	--border-radius: 1rem;
	--border-thickness: 3px;
}

*,
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'kern' 1;
	font-kerning: normal;
	text-wrap: balance;
	font-family: var(--font-monument-mono);
	font-weight: 400;
	box-sizing: border-box;

	@media (min-width: 768px) {
		text-align: center;
	}
}

body {
	padding: 0;
	margin: 0;
	display: grid;
	place-items: center;
	color: var(--color-secondary);
	background: var(--color-primary);
	font-size: 16px; /* Base font size for rem scaling. */
}

main {
	position: relative;
	z-index: 1;
	text-wrap: balance;
	padding: 1rem 1rem 0 1rem;
	display: flex;
	flex-direction: column;
	gap: 6rem;
	mix-blend-mode: difference;
	font-size: 2rem;
	letter-spacing: -0.01em;
	line-height: 1.15;
	font-family: var(--font-monument);

	@media (min-width: 768px) {
		gap: 8rem;
		line-height: 1.05;
		font-size: 3.35vw;
		padding: 20rem 5rem 0rem 5rem;
	}
}

/* Background video (non-interactive) */
video {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	height: 100lvh;
	object-fit: cover;
	pointer-events: none;
}

footer {
	font-size: 1rem;
	background: var(--color-secondary);
	color: var(--color-primary);
	padding: 3rem;
	border-radius: var(--border-radius) var(--border-radius) 0 0;

	@media (min-width: 768px) {
		padding: 7rem 3rem;
		font-size: 1.5rem;
	}
}

h1,
h2,
p {
	margin: 0;
	font-size: inherit;
}

h1,
h2 {
	margin-bottom: 3rem;
}

p {
	margin: 0 auto;
}

p a {
	text-decoration: underline;
	text-decoration-thickness: var(--border-thickness);
}

a {
	cursor: pointer;
	text-decoration: none;
	color: var(--color-secondary);
	display: inline-block;
	font-family: inherit;
}

li {
	all: unset;
	font-family: var(--font-monument);
}

button {
	all: unset;
	cursor: pointer;
}

ul {
	all: unset;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	gap: 0.75rem;

	@media (min-width: 768px) {
		justify-content: center;
		margin: 0 auto;
	}
}

ul a {
	padding: 0.25rem 0.95rem;
	border-radius: var(--border-radius);
	outline: var(--border-thickness) solid var(--color-secondary);
	background: var(--color-secondary);
	color: var(--color-primary);
	text-decoration: none;
}

li:not(:has(a)) {
	padding: 0.25rem 0.95rem;
	border-radius: var(--border-radius);
	outline: var(--border-thickness) solid var(--color-secondary);
	background: var(--color-primary);
	color: var(--color-secondary);
}

ul a:hover {
	@media (pointer: fine) {
		background: var(--color-primary);
		color: var(--color-secondary);
	}
}

ul a:focus-visible {
	opacity: 0.8;
}

ul a:active {
	opacity: 0.8;
}

/* Research Section */

/* Round sections */
.researchModal {
	--researchModal-padding-y: 10rem;

	width: 100%;
	border-radius: 50%;
	background: var(--color-secondary);
	color: var(--color-primary);
	transition: border-radius 0.2s var(--ease-smooth);
	border: var(--border-thickness) solid transparent;
	overflow: hidden;
}

/* Round sections -- hover styles */
.researchModal:hover,
.researchModal:focus-visible,
.researchModal--active {
	border-radius: var(--border-radius);
	transition-speed: 0.1s;
}

.researchModal_toggle-btn {
	padding: var(--researchModal-padding-y) 0;
	height: 100%;
	width: 100%;
	user-select: none;
}

.researchModal_content {
	height: 0;
}

.researchModal_content--show {
	margin-bottom: var(--researchModal-padding-y);
	height: 100%;
}
